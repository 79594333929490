@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.achieveImage {
  background-image: url('../assets/homepage/DIPP.png');
  height: 89.44543828264759vh;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  margin-bottom: 4rem;
  margin-top: 2vh;
}

.Achieve h2 {
  text-align: center;
  font-size: 5rem;
  font-weight: 800;
  color: #702fff !important;
  font-family: 'Merriweather Sans', sans-serif;
  padding: 2rem 0 -4rem 0;
}
.Achieve h2 a:link {
  text-decoration: none;
}

.Achieve h2 a:visited {
  text-decoration: none;
  /* color: #6d6b6b; */
  color: #702fff;
}

.Achieve h2 a {
  color: #702fff;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: default;
}

.Achieve h2 a:hover {
  color: #702fff;
  text-shadow: 0px 0px 30px #2e65ff;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.image {
  width: 330px;
  height: auto;
  border-radius: 8px;
  margin-top: 50px;
}

@keyframes enter-animation {
  0% {
    transform: translateY(150px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.defaultHR {
  width: 100%;
  position: absolute;
  size: 0.8944543828264758vh;
  color: #d0d0d0;
}

.partners {
  color: white;
  text-align: center;
  /* margin: 0% 5%; */
  padding-bottom: 4%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.partnersH2 {
  padding-top: 4%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  color: #9b45b4 !important;
  font-family: 'Merriweather Sans', sans-serif;
  padding: 2rem 0 -4rem 0;
}
.partnersH2 a:link {
  text-decoration: none;
}

.partnersH2 a:visited {
  text-decoration: none;
  /* color: #6d6b6b; */
  color: #9b45b4;
}

.partnersH2 a {
  color: #9b45b4;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: default;
}

.partnersH2 a:hover {
  color: #9b45b4;
  text-shadow: 0px 0px 30px #ae5cc7;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.imageInfotech {
  width: 180px;
  transition: all 0.25s ease-in-out;
}
.imageInfotech:hover {
  scale: 1.2;
}
.partners h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: large;
  font-weight: 400;
}

/* .g1-divider {
  margin: 0 1rem;
}
.g1-divider:after,
.g1-divider:before {
  display: block;
  height: 4px;
  border: #702fff dotted;
  border-width: 4px 0;
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-style: dotted;
  content: '';
} */

@media (prefers-reduced-motion: no-preference) {
  .achieveImage-animation {
    animation: enter-animation 2s 1;
  }
}

@media (max-width: 700px) {
  .partners h2 {
    font-size: 3.5rem;
  }
}
@media (max-width: 610px) {
  .Achieve h2 {
    font-size: 4rem;
  }
  .partners h2 {
    font-size: 3rem;
  }
}

@media (max-width: 560px) {
  .Achieve h2 {
    font-size: 3rem;
  }
  .partners h3 {
    font-size: medium;
  }
  .partners {
    flex-direction: column !important;
    justify-content: center;
  }
  .achieveImage {
    margin-bottom: 0;
    margin-top: -4vh;
  }
  .imageInfotech {
    margin-top: 1rem;
  }
}
@media (max-width: 450px) {
  .achieveImage {
    margin-bottom: -5%;
    margin-top: -15%;
  }
}
@media (max-width: 400px) {
  * {
    width: -150vw;
  }
  .Achieve h2 {
    font-size: 2.5rem;
    margin-bottom: -30%;
  }
  .partners h2 {
    font-size: 2.5rem;
  }

  .achieveImage {
    margin-bottom: -10%;
    margin-top: 10%;
  }
}
@media (max-width: 310px) {
  .Achieve h2 {
    font-size: 2rem;
  }
  .partners h2 {
    font-size: 2rem;
  }
  .achieveImage {
    margin-bottom: -30%;
    margin-top: 0%;
  }
}
@media (max-width: 240px) {
  .Achieve h2 {
    font-size: 1.8rem;
  }
  .partners h3 {
    font-size: smaller;
  }
}
