.backgroundimg {
  /* background-image: url('../assets/homepage/feedbackBackground.jpg'); */
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center 30%;
  background-repeat: no-repeat;
  background: linear-gradient(#251b58, #702fff 35%, #2e65ff);
}

.back {
  position: absolute;
  color: white;
  top: 3vh;
  left: 3vw;
  padding: 0.2vw 0.7vh 0vw;
}
.back:hover {
  position: absolute;
  top: 3vh;
  left: 3vw;
  width: 40px;
  background: rgba(218, 218, 218, 0.162);
  border-radius: 7px;
  padding: 0.2vw 0.7vh 0vw;
}

/* .feedbackForm {
  margin: 0 auto;
  transform: translate(50%, 50%);
} */

.feedbackContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.feedbackContent input {
  margin-top: 10px;
  background: none;
  border-bottom: solid 0.5px rgb(255, 255, 255);
}
.feedbackContent input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

.fh2 {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
}

.star-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  padding: 1%;
  color: #2b00ff;
}

.star-icon input {
  display: none;
}

.star-icon input + label {
  cursor: pointer;
}

.star-icon input:checked + label ~ label {
  color: #251b58;
}

@media (max-width: 900px) {
  .feedbackContent {
    transform: translate(35%, 0%);
  }
}

@media (max-width: 528px) {
  .feedbackContent {
    transform: translate(20%, 0%);
  }
}

@media (max-width: 420px) {
  .feedbackContent {
    transform: translate(10%, 0%);
  }
}

@media (max-width: 370px) {
  .feedbackContent {
    transform: translate(0%, 0%);
  }
}
