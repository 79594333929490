@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Ubuntu:wght@300;400&display=swap');

.servicesGlassyEffect {
  height: 12.522361359570661vh;
  width: 100%;
  /* background: rgba(8, 8, 8, 0.418); */
  background: white;
  z-index: -1;
  float: left;
}

/* .callingLady {
  background-image: url('../assets/homepage/contact-us-modified.png');
  background-repeat: no-repeat;
  z-index: 0;
  height: 55rem;
  width: 100vw;
}

.callHeading {
  height: 55rem;
  padding-top: 17rem;
  padding-left: 50%;
}

.callHeading h1 {
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 60px;
}

.callHeading p {
  font-family: 'Ubuntu', sans-serif;
  font-size: 25px;
} */

/* .ContactspaceMaker {
  background-color: white;
  height: 95rem;
  width: 100%;
  margin: 0;
  padding: 0%;
} */

/* .ourServices {
  background: white;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 65rem;
  width: 100%;
  top: 50rem;
} */

.ourServices h1 {
  background-color: white;
  text-align: center;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 50px;
  font-weight: 700;
  color: #702fff;
  padding-top: 6rem;
}

/* p {
  text-align: center;
  color: #fdec6e;
} */

.ourServices h1 a:link {
  text-decoration: none;
}

.ourServices h1 a:visited {
  text-decoration: none;
  /* color: #6d6b6b; */
  color: #702fff;
}

.ourServices h1 a {
  color: #702fff;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: default;
}

.ourServices h1 a:hover {
  color: #702fff;
  text-shadow: 0px 0px 30px #2e65ff;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.serviceContainer {
  margin: 0px 150px 100px 150px;
  margin-bottom: 0 !important;
  margin-top: 3rem;
  padding: 60px;
  position: static;
  background-color: white;
  z-index: 1000;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 20rem 20rem 20rem 20rem;
  grid-template-areas:
    'pic1  text1'
    'text2  pic2'
    'pic3   text3'
    'text4  pic4';
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.gridHeading {
  color: #6d6b6b;
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 700;
}
.gridHeading a:link {
  text-decoration: none;
}

.gridHeading a:visited {
  text-decoration: none;
  color: #6d6b6b;
}

.gridHeading a {
  color: #6d6b6b;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: default;
}

.gridHeading a:hover {
  color: #702fff;
  text-shadow: 0px 0px 30px #2e65ff;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.el1 {
  background-image: url('../assets/homepage/robot.png');
  background-repeat: no-repeat;
  background-size: 15rem;
  background-position: top center;
  transition: all 0.25s ease-in-out;
  grid-area: pic1;
}
.el1:hover {
  scale: 1.4;
}

.el2 {
  grid-area: text1;
  color: black;
  font-family: 'Merriweather Sans', sans-serif;
}

.el3 {
  grid-area: text2;
  color: black;
  font-family: 'Merriweather Sans', sans-serif;
  text-align: right;
}

.el4 {
  background-image: url('../assets/homepage/artificial-intelligence\ \(1\).png');
  background-repeat: no-repeat;
  background-size: 15rem;
  background-position: top center;
  transition: all 0.25s ease-in-out;
  grid-area: pic2;
}
.el4:hover {
  scale: 1.4;
}

.el5 {
  background-image: url('../assets/homepage/automated\(1\).png');
  background-repeat: no-repeat;
  background-size: 15rem;
  background-position: top center;
  transition: all 0.25s ease-in-out;
  grid-area: pic3;
}
.el5:hover {
  scale: 1.4;
}

.el6 {
  grid-area: text3;
  color: black;
  font-family: 'Merriweather Sans', sans-serif;
}

.el7 {
  grid-area: text4;
  color: black;
  font-family: 'Merriweather Sans', sans-serif;
  text-align: right;
}

.el8 {
  background-image: url('../assets/homepage/maintenance.png');
  background-repeat: no-repeat;
  background-size: 15rem;
  background-position: top center;
  transition: all 0.25s ease-in-out;
  grid-area: pic4;
}
.el8:hover {
  scale: 1.4;
}

.gradC1 {
  border-radius: 286px;
  background: #2b00ff;
  filter: blur(200px);
  -webkit-filter: blur(186px);
  width: 286px;
  height: 286px;
  flex-shrink: 0;
  position: absolute;
  left: -10vw;
}

.gradE1 {
  border-radius: 60px;
  background: #2b00ff;
  filter: blur(201.5px);
  width: 100.317px;
  height: 650.189px;
  transform: rotate(37.147deg);
  flex-shrink: 0;
  position: absolute;
  left: 70vw;
}

@media (max-width: 1000px) {
  .elementPara {
    font-size: 0.8rem;
  }
  .elementImage {
    background-size: 13rem;
    margin-top: 20%;
  }
  .serviceContainer {
    grid-row-gap: 20px;
  }
}
@media (max-width: 800px) {
  .elementPara {
    font-size: 0.6rem;
  }
  .elementImage {
    background-size: 8rem;
  }
  .serviceContainer {
    grid-row-gap: 20px;
    grid-template-rows: 15rem 15rem 15rem 15rem;
  }
  .ContactspaceMaker {
    height: 75rem;
  }
}
@media (max-width: 700px) {
  .serviceContainer {
    display: block;
    text-align: left;
    padding-top: 0px;
  }
  .elementPara {
    font-size: 0.8rem;
    text-align: center;
  }

  .el1,
  .el2,
  .el3,
  .el4,
  .el5,
  .el6,
  .el7,
  .el8 {
    margin-top: 20%;
  }
  .el1,
  .el4,
  .el5,
  .el8 {
    display: none !important;
  }
  .ContactspaceMaker {
    height: 80rem;
  }
}

@media (max-width: 600px) {
  .elementPara {
    font-size: 0.7rem;
  }
  .serviceContainer {
    padding: 0px;
  }
}
@media (max-width: 500px) {
  .elementPara {
    font-size: 0.7rem;
    width: 100%;
  }

  hr {
    display: none;
  }
  .serviceContainer {
    padding: 0px;
    width: 60vw;
    margin: auto 90px;
  }
  .ContactspaceMaker {
    height: 60rem;
  }
  .ourServices h1 {
    font-size: 40px;
  }
}
@media (max-width: 440px) {
  .serviceContainer {
    width: 50vw;
  }
  .ContactspaceMaker {
    height: 65rem;
  }
}
@media (max-width: 360px) {
  .elementPara {
    font-size: 0.6rem;
  }
  .ContactspaceMaker {
    height: 60rem;
  }
}
@media (max-width: 300px) {
  .serviceContainer {
    width: 40vw;
  }
  .elementPara {
    font-size: 0.5rem;
  }
  .ourServices h1 {
    font-size: 30px;
  }
}
