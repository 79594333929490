.termsConditions {
  /* color:dimgrey; */
  /* background-color: #251b58; */
  background-color: black;
  color: whitesmoke;
}

.termsText {
  padding: 3vw 10vh;
  margin-top: 12.522361359570661vh;
}
.termsText h2 {
  font-size: 3rem;
}

@media (max-width: 786px) {
  .termsText {
    padding: 3vw 5vh;
    margin-top: 15.522361359570661vh;
  }
  .termsText h2 {
    font-size: 2.5rem;
  }
  .termsText p {
    font-size: 0.8rem;
  }
}
