@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.about {
  /* background-color: #251b58; */
  background-color: white;
  justify-content: center;
  padding: 10rem;
  padding-bottom: 5rem !important;
  padding-top: 0rem !important;
  width: 100vw;
  /* height: 175vh; */
}
.upperAbout {
  background-color: white;
  border: blur;
  justify-content: center;
  padding: 10rem;
  padding-top: 0rem !important;
  width: 100vw;
  /* box-shadow: 0 0 15px 25px white; */
}
.whiteLowerSpaceMaker {
  background-color: white;
  height: 5.724508050089446vh;
}
.g1-divider {
  margin: 4rem 1rem;
}
.g1-divider:after,
.g1-divider:before {
  display: block;
  height: 4px;
  border: #9b45b4 dotted;
  border-width: 4px 0;
  margin-bottom: 4px;
  padding-bottom: 4px;
  /* border-style: dotted; */
  content: '';
}

/* Inline #12 | http://localhost:3000/ */

.g1-divider::after,
.g1-divider::before {
  /* border: #702fff dotted; */
  border: #9b45b4 dotted;
}

.lowerAboutUs {
  display: block;
}

/* cards CSS */
.landscape-card {
  margin-top: 60px;
  display: flex;
  align-items: center;
  width: 100%;
}

.landscape-card-image-zeno {
  background: url('../assets/homepage/zenoHome.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50%;
  height: 60vh;
  object-fit: contain;
}
.landscape-card-image-zini {
  background: url('../assets/homepage/ziniHome.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50%;
  height: 60vh;
  object-fit: contain;
  margin-top: 4rem;
}
.landscape-card-image-zoro {
  background: url('../assets/homepage/zoroHome.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 50%;
  height: 60vh;
  object-fit: contain;
}

.landscape-card-image img {
  width: 100%;
  height: auto;
}

.landscape-card-title-zeno {
  display: block;
  background-image: url('../assets/homepage/zenoIcon.png');
  background-position: center;
  height: 40vh;
  background-size: contain;
  background-repeat: no-repeat;
}
.landscape-card-title-zini {
  display: block;
  background-image: url('../assets/homepage/ziniIcon.png');
  background-position: center;
  height: 40vh;
  background-size: contain;
  background-repeat: no-repeat;
}
.landscape-card-title-zoro {
  display: block;
  background-image: url('../assets/homepage/zoroIcon.png');
  background-position: center;
  height: 40vh;
  background-size: contain;
  background-repeat: no-repeat;
}

.landscape-card-content {
  margin-top: -2rem;
  font-size: medium;
  padding-left: 30px;
  color: black;
  width: 50%;
}

.landscape-card-content h2,
.landscape-card-content h3,
.landscape-card-content p {
  margin: -10px;
}
.headaboutbtn2 {
  margin-top: 2rem;
  background-color: #9b45b4;
  color: white;
  padding: 1% 4%;
  border-radius: 5px;
  font-weight: 500;
  font-size: medium;
}

.headaboutbtn2:hover {
  /* box-shadow: #2e65ff 5px 5px 5px 5px;
  filter: blur(10);
  */
  background-color: #9b45b4;
  box-shadow: 0px 0px 30px #b058cb;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.landscape-card {
  display: flex;
  flex-direction: column;
}

.landscape-card-image,
.landscape-card-content {
  width: 100%;
}

.landscape-card-image img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .landscape-card {
    flex-direction: row;
  }

  .landscape-card-image,
  .landscape-card-content {
    width: 50%;
  }
}
.faltuHeading {
  text-align: left;
  font-size: 5rem;
  font-weight: 800;
  color: white !important;
  font-family: 'Merriweather Sans', 'sans-serif';
  margin-right: 2.5rem;
}
.faltuHeading-animation {
  animation: 0.5s leftToRight;
}
.faltuText {
  color: white;
  font-size: x-large;
}
.faltuText a {
  color: #6822ff;
  text-shadow: 0px 0px 30px #2e65ff;
  cursor: pointer;
  font-weight: 500;
}
.faltuText-animation {
  animation: 0.5s leftToRight;
}
@keyframes leftToRight {
  0% {
    margin-left: -100vw;
    opacity: 0;
  }

  100% {
    margin-left: 0vw;
    opacity: 1;
  }
}
.aboutp {
  text-align: left;
  font-size: 1.3rem;
  color: black !important;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  margin-top: 2.5rem;
  letter-spacing: 0.1px;
  word-spacing: 1.5px;
  line-height: 37px;
}

.imgs {
  box-shadow: rgba(0, 0, 0, 0.9) 0px 54px 55px,
    rgba(0, 0, 0, 0.9) 0px -12px 30px, rgba(0, 0, 0, 0.9) 0px 4px 6px,
    rgba(0, 0, 0, 0.9) 0px 12px 13px, rgba(0, 0, 0, 0.9) 0px -3px 5px;
  overflow: hidden;
}
.imgs-animation {
  animation: 2.5s rightToLeft;
}
@keyframes rightToLeft {
  /* 0%{
    margin-left: 50vw;
    opacity: 0;
    scale: 1;
  }
  25%{
    opacity: 0.25;
    scale: 1;
  }
  50%{
    opacity: 0.5;
    scale: 1;
  }
  75%{
    opacity: 0.75;
    scale: 1;
  }
  100%{
    margin-left: 0;
    opacity: 1;
    scale: 1;
  } */
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
/* .upperAbout{
  display: flex;
  align-items: center;
 justify-content: center;
} */

/* .abouth2{
  text-align: center;
  font-size: 10rem;
  font-weight: 800;
  color: white !important;
  margin-right: -0rem;
transform: rotate(-90deg);
z-index: 1000000000000;
word-wrap: break-word;
white-space: nowrap;
max-height: 3vh;
opacity: 0.7;
margin-left: -50vh;
font-family: 'Bebas Neue', sans-serif;
} */

/* Inline #9 | http://localhost:3000/ */

.headingabout {
  /* font-size: 5rem; */
  font-size: 4rem;
}

.h22 {
  font-family: 'Merriweather Sans', sans-serif;
  /* -webkit-text-stroke: 4px white; */
  color: transparent;
  /* letter-spacing: 10px; */
  text-align: center;
  font-size: 5rem;
  font-weight: 800;
  color: #9b45b4 !important;
  margin-right: 2.5rem;
}
.h22 a:link {
  text-decoration: none;
}

.h22 a:visited {
  text-decoration: none;
  color: #9b45b4;
}

.h22 a {
  color: #9b45b4;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: default;
}

.h22 a:hover {
  color: #9b45b4;
  text-shadow: 0px 0px 30px #ae5cc7;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.headingabout {
  text-align: center;
  font-size: 5rem;
  font-weight: 800;
  color: #9b45b4 !important;
  font-family: 'Merriweather Sans', sans-serif;
  padding: 2rem 0 -4rem 0;
}
.headingabout a:link {
  text-decoration: none;
}

.headingabout a:visited {
  text-decoration: none;
  /* color: #6d6b6b; */
  color: #9b45b4;
}

.headingabout a {
  color: #9b45b4;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: default;
}

.headingabout a:hover {
  color: #9b45b4;
  text-shadow: 0px 0px 30px #ae5cc7;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.gallery {
  height: 60vh;
  /* padding: 5% 5%; */
  background-color: black;
  border-radius: 10px;
  margin-top: 5%;
  box-shadow: black 5px 5px 5px 5px;
}

.gallery-img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.headingtopabout {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 0% 0;
}

.headingtopabout > h2 {
  color: white;
  font-size: 7vh;
  font-weight: 700;
  text-align: left;
}

.headaboutbtn {
  background-color: #5c48c5;
  color: white;
  padding: 1% 4%;
  border-radius: 5px;
  font-weight: 500;
  font-size: large;
}

.headaboutbtn:hover {
  /* box-shadow: #2e65ff 5px 5px 5px 5px;
  filter: blur(10);
  */
  background-color: #6d55e4;
  box-shadow: 0px 0px 30px #2e65ff;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.aboutimgl {
  transition: all 0.25s ease-in-out;
}

.aboutimgl:hover {
  scale: 1.1;
}

.videocarousel {
  height: 60vh;
  width: 100%;
  object-fit: contain;
  background-color: black;
  box-shadow: 5px 5px 5px black;
  border-radius: 25px;
  margin-top: 4%;
}

.aboutCar {
  margin-top: 1%;
}

.headingabout {
  margin-top: 5%;
  text-align: center;
  font-size: 4rem;
  font-weight: 800;
  text-decoration: none;
  color: #ffffff !important;
  font-family: 'Merriweather Sans', sans-serif;
  padding: 2rem 0 -4rem 0;
}

.divPic {
  object-fit: contain;
  max-height: max-content;
  height: 80vh;
}

.secondPic {
  object-fit: contain;
}

.secimgabout {
  height: 100%;
  width: 100%;
}

@media (max-width: 800px) {
  .imgs {
    width: 100vw;
  }
  .faltuHeading {
    font-size: 4rem;
  }
  .faltuText {
    font-size: large;
  }
  .headaboutbtn {
    font-weight: 400;
  }
  .divPic {
    height: 70vh;
  }
}
@media (max-width: 800px) {
  .headaboutbtn {
    font-weight: 400;
  }
}

@media (max-width: 510px) {
  .headaboutbtn {
    font-size: small;
  }
}
@media (max-width: 774px) {
  .h22 {
    font-size: 4rem;
    margin-right: 2rem;
  }
  .aboutp {
    font-size: 1rem;
    line-height: 30px;
  }
  .faltuHeading {
    font-size: 4rem;
  }
  .faltuText {
    font-size: 1rem;
  }
  .about {
    padding: 8rem;
  }
  .upperAbout {
    padding: 8rem;
  }
  .divPic {
    height: 50vh;
  }
  .headingabout {
    /* font-size: 5rem; */
    font-size: 4rem;
  }
}
@media (max-width: 600px) {
  .h22 {
    font-size: 3rem;
    margin-right: 2rem;
  }
  .aboutp {
    font-size: 0.8rem;
    line-height: 30px;
  }
  .faltuHeading {
    font-size: 3rem;
  }
  .faltuText {
    font-size: 0.8rem;
  }
  .about {
    padding: 6rem;
  }
  .upperAbout {
    padding: 6rem;
  }
  .divPic {
    height: 45vh;
  }
  .headingabout {
    /* font-size: 5rem; */
    font-size: 3rem;
  }
}
@media (max-width: 510px) {
  .landscape-card-image-zini {
    background-size: auto;
    width: inherit;
    height: 70vh;
  }
  .zenoAbout {
    margin-bottom: 7rem;
  }
  .ziniAbout {
    margin-bottom: 4rem !important;
  }
  .h22 {
    font-size: 2rem;
    margin-right: 1rem;
  }
  .aboutp {
    font-size: 1rem;
    line-height: 20px;
    margin-top: 0;
  }

  .headingtopabout h2 {
    font-size: 5vh !important;
  }
  .faltuHeading {
    font-size: 1.2rem;
    margin-top: -10%;
    width: 30%;
  }
  .faltuText {
    font-size: 0.7rem;
  }
  .lowerdivwrapper {
    margin-top: -30%;
  }
  .imgs {
    margin-top: -30%;
    width: 50%;
  }
  .headaboutbtn {
    font-size: small;
  }

  .about {
    padding: 4rem;
  }
  .upperAbout {
    padding: 4rem;
  }
}

@media (max-width: 400px) {
  .about {
    min-width: 100vw;
    overflow-x: hidden;
    margin-top: -40%;
  }
  .upperAbout {
    min-width: 100vw;
    overflow-x: hidden;
    margin-top: 5rem;
    height: 80vh;
  }
  .h22 {
    font-size: 1.6rem;
    margin-right: 0.8rem;
  }
  .aboutp {
    font-size: 1rem;
    line-height: 20px;
    height: auto;
    margin-top: 0;
  }
  .faltuHeading {
    font-size: 1.2rem;
    margin-top: -10%;
    width: 30%;
  }
  .faltuText {
    font-size: 0.7rem;
  }
  .lowerdivwrapper {
    margin-top: -30%;
  }
  .imgs {
    margin-top: -30%;
    width: 50%;
  }

  .about {
    padding: 2rem;
    width: 100vw;
    overflow: hidden;
  }
  .upperAbout {
    padding: 2rem;
    width: 100vw;
    overflow: hidden;
  }
  .videocarousel {
    /* background-color: #251b58; */
    background-color: black;
    object-fit: cover;
    box-shadow: none;
    height: 40vh;
  }
  .divPic {
    height: 40vh;
  }
  .headingabout {
    /* font-size: 5rem; */
    font-size: 2.5rem;
  }
}
@media (max-width: 280px) {
  .aboutp {
    font-size: 0.4rem;
    line-height: 12px;
    height: auto;
    margin-top: -4vh;
  }
  .faltuHeading {
    font-size: 0.9rem;
    margin-top: -10%;
    width: 30%;
  }
  .faltuText {
    font-size: 0.5rem;
  }
  .divPic {
    height: 30vh;
  }
  .headingabout {
    /* font-size: 5rem; */
    font-size: 2rem;
  }
}
