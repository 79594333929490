@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.button-page-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.button-container {
  max-width: 28rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(102, 126, 234, 0.1),
    rgba(118, 75, 162, 0.1)
  );
  pointer-events: none;
  z-index: 1;
}

.content-stack {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
}

.heading {
  color: #2d3748;
  font-size: 1.875rem;
  margin-bottom: 1rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  gap: 0.5rem;
}

.button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.button-purple {
  background-color: #805ad5;
  color: white;
}

.button-blue {
  background-color: #4299e1;
  color: white;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
}

.globe-icon::before {
  content: '🌐';
  position: absolute;
}

.arrow-icon::before {
  content: '→';
  position: absolute;
}

.footer-text {
  color: #718096;
  font-size: 0.875rem;
  margin-top: 1rem;
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.5rem;
  }

  .button-container {
    padding: 1.5rem;
  }
}
