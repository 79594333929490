@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  max-width: 100vw;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
  object-fit: cover;
  background-color: black;
}

.feedbackButton {
  color: white;
  height: 11vh;
  width: 8vw;
  font-size: 20px;
}

.feedbackButton:hover {
  background-color: #6d55e4;
  box-shadow: 0px 0px 30px white;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.wrapp {
  overflow-x: hidden;
  width: 100%;
}

.defaultHR {
  width: 100%;
  position: absolute;
  size: 0.8944543828264758vh;
  color: #d0d0d0;
}

.spaceMaker {
  background: linear-gradient(rgba(0, 0, 0) 1%, #251b58 99%);
  height: 14.311270125223613vh;
  width: 100%;
  margin: 0;
  padding: 0%;
  box-shadow: 0 0 10px 20px black;
}

.lowerSpaceMaker {
  /* background-color: #251b58; */
  background-color: white;
  height: 5.724508050089446vh;
  width: 100%;
  margin: 0;
  padding: 0%;
}

.hometext h2 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 6.2rem;
  text-align: center;
  color: white !important;
  font-family: 'Merriweather Sans', sans-serif;
  animation: 2s leftToRightH2;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px 5px 0 0;
  padding: 10px;
}

.hometext h5 {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0 0 5px 5px;
  padding: 5px;
  font-size: 1rem;
  font-weight: 200;
  text-align: left;
  margin-bottom: 1.2rem;
  color: white !important;
  font-family: 'Merriweather Sans', sans-serif;
  animation: 2s leftToRightH5;
  z-index: 2;
}

.hometext Button {
  animation: 2s leftToRightButton;
  z-index: 2;
}

@keyframes leftToRightH2 {
  0% {
    margin-left: -80vw;
    opacity: 0;
  }

  100% {
    margin-left: 0vw;
    opacity: 1;
  }
}

@keyframes leftToRightH5 {
  0% {
    margin-left: -90vw;
    opacity: 0;
  }

  100% {
    margin-left: 0vw;
    opacity: 1;
  }
}

@keyframes leftToRightButton {
  0% {
    margin-left: -90vw;
    opacity: 0;
  }

  100% {
    margin-left: 0vw;
    opacity: 1;
  }
}

.home {
  /* background-color: #251b58; */
  background-color: white;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.gradient {
  /* background: url(../assets/homepage/Group\ 7.png); */
  position: absolute;
  z-index: -100;
}

.gradC1 {
  border-radius: 286px;
  background: #2b00ff;
  filter: blur(100px);
  -webkit-filter: blur(186px);
  width: 286px;
  height: 286px;
  flex-shrink: 0;
  position: absolute;
  left: -10vw;
  display: none;
}

.gradE1 {
  border-radius: 60px;
  background: #2b00ff;
  filter: blur(201.5px);
  width: 100.317px;
  height: 650.189px;
  transform: rotate(37.147deg);
  flex-shrink: 0;
  position: absolute;
  left: 70vw;
  display: none;
}

.ourteam {
  font-family: 'Merriweather Sans', sans-serif;
  padding: 2rem 0 -4rem 0;
  margin-bottom: 20px;
}

.teamheading {
  text-align: center;
  font-size: 5rem;
  font-weight: 800;
  color: white !important;
}

@media (max-width: 1050px) {
  html {
    overflow-x: hidden;
  }

  .hometext h2 {
    font-size: 3.2rem;
    line-height: 4.2rem;
  }

  .hometext h5 {
    font-size: 1.4rem;
  }
  .feedbackButton {
    width: 20vw;
  }
  .headImage {
    height: 36rem !important;
  }

  .glassyEffect {
    height: 14.522361359570661vh !important;
  }
}

@media (max-width: 900px) {
  .hometext h2 {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  .hometext h5 {
    font-size: 1.2rem;
  }

  .feedbackButton {
    width: 20vw;
  }
  .headImage {
    height: 36rem !important;
  }
}

@media (max-width: 700px) {
  html {
    overflow-x: hidden;
  }
  .hometext {
    margin-left: -40%;
  }

  .hometext h2 {
    font-size: 3rem;
    line-height: 3.7rem;
  }

  .hometext h5 {
    font-size: 1.2rem;
  }

  .feedbackButton {
    width: 20vw;
  }
  .headImage {
    height: 35rem !important;
  }
}

@media (max-width: 600px) {
  @keyframes leftToRightH2 {
    0% {
      /* margin-left: -80vw; */
      opacity: 0;
    }

    100% {
      /* margin-left: 0vw; */
      opacity: 1;
    }
  }

  @keyframes leftToRightH5 {
    0% {
      /* margin-left: -90vw; */
      opacity: 0;
    }

    100% {
      /* margin-left: 0vw; */
      opacity: 1;
    }
  }

  @keyframes leftToRightButton {
    0% {
      /* margin-left: -90vw; */
      opacity: 0;
    }

    100% {
      /* margin-left: 0vw; */
      opacity: 1;
    }
  }
  .headImage {
    height: 36rem !important;
  }

  .hometext h2 {
    font-size: 3rem;
    line-height: 3.7rem;
  }

  .hometext h5 {
    font-size: 1.2rem;
  }

  .feedbackButton {
    width: 20vw;
  }
}

@media (max-width: 480px) {
  .hometext {
    margin-top: 20%;
    /* background: rgba(0, 0, 0, 0.7);  Added transparent black overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hometext h2 {
    width: 200vw;
    font-size: clamp(1.4rem, 4vw, 1.8rem);
    text-align: center;
    margin: 0 auto;
    padding: 0.5rem;
    position: relative;
    left: 15%; /* Adjusted for center alignment */
  }

  .hometext h5 {
    font-size: clamp(0.8rem, 3vw, 1rem);
    text-align: center;
    margin: 0 auto;
    padding-left: 50vw;
    padding: 0.5rem 2.5rem; /* Adjusted padding */
    max-width: 100%;
    position: relative;
    padding-inline: 10%;
    word-wrap: normal;
    left: 15%; /* Adjusted for center alignment */
  }

  .gradC1,
  .gradE1 {
    display: none;
  }
}

@media (max-width: 280px) {
  .hometext {
    margin-top: 50%;
  }

  .hometext h2 {
    font-size: clamp(1.5rem, 5vw, 2rem);
  }
}
