.product-detail-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-page {
  padding: 40px 2vw;
  /* background-color: #251b58; */
  background-color: black;
}

.zh {
  font-size: 20vh;
}

.headtext {
  font-size: larger;
  font-weight: 700;
}

.carousel-container {
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  max-height: 60vh;
  padding-top: 3vh;
  margin-bottom: 3vh;
}

.carousel-containerimg {
  overflow: hidden;
  width: 100%;
  max-height: 60vh;
  object-fit: contain;
}

.product-section {
  color: white !important;
}

.product-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.product-images img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
}

.product-details {
  text-align: center;
  margin-bottom: 20px;
}

.product-details h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: white !important;
}

.product-details p {
  font-size: 16px;
  color: white !important;
}

@media (max-width: 768px) {
  .product-images {
    flex-direction: column;
    align-items: center;
  }
}
