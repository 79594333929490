.newspage {
  /* background: url('../assets/homepage/newsletterBackground.jpg'); */
  /* background-size: cover;
  width: 300vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center; */
  top: 2%;
  /* background: linear-gradient(#251b58, #702fff 35%, #2e65ff); */
  background: linear-gradient(black, rgb(116, 116, 116) 35%, rgb(34, 34, 34));
  margin: auto;
}
#newsletter {
  /* background: linear-gradient(#251b58, #702fff, #2e65ff); */
  background: linear-gradient(black, #747474 35%, #222222);
  height: 100vh;
}

.logintext {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
}

.news {
  background: red;
  width: 30%;
}

.newsletterContent {
  background: rgba(110, 110, 110, 0.39);
  color: white;
  margin-top: -9%;
  padding: 1%;
  width: 20%;
  border-radius: 3%;
}

.newsletterContent input {
  margin-top: 10px;
  background: none;
  /* border-bottom: solid 0.5px rgb(77, 77, 77); */
  border-bottom: solid 0.5px white;
}

.newsletterContent input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}

@media (max-width: 900px) {
  .newsletterContent {
    transform: translate(35%, 0%);
  }
}

@media (max-width: 528px) {
  .newsletterContent {
    transform: translate(20%, 0%);
  }
}

@media (max-width: 420px) {
  .newsletterContent {
    transform: translate(10%, 0%);
  }
}

@media (max-width: 370px) {
  .newsletterContent {
    transform: translate(0%, 0%);
  }
}
