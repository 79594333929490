@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap');

.flex {
  display: flex;
  justify-content: space-around;
}

.margin {
  margin-top: 30px;
}
.footer {
  background-color: black;
  /* background-color: #251b58; */
}

.defaultHR {
  color: #b0b0b0;
  height: 0.8px;
  border-top: 0.8px solid;
}

.footerDivider {
  background: black;
  height: 5px;
  width: 100%;
  margin: 0;
  padding: 0%;
}
.container {
  display: grid;
  background: #8b8b8b;
  grid-template-columns: repeat(3, 33.3%);
  grid-template-rows: repeat(4, 5rem);
  grid-template-areas:
    'contact products address'
    'feedback newsletter email'
    'about  terms  phone'
    'icon icon logo';
  margin: 40px;
  grid-column-gap: 1px;
}

.contact {
  background: black;
  grid-area: contact;
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.products {
  background: black;
  grid-area: products;
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.address {
  background: black;
  grid-area: address;
  padding-top: 1rem;
  padding-left: 4rem;
  cursor: pointer;
  color: #fff;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.feedback {
  background: black;
  grid-area: feedback;
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.newsletter {
  background: black;
  grid-area: newsletter;
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.email {
  background: black;
  grid-area: email;
  padding-left: 4rem;
  padding-top: 1rem;
  cursor: pointer;
  color: #fff;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.footerAbout {
  background: black;
  grid-area: about;
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.terms {
  background: black;
  grid-area: terms;
  text-align: center;
  padding-top: 1rem;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.phone {
  background: black;
  grid-area: phone;
  padding-left: 4rem;
  padding-top: 1rem;
  cursor: pointer;
  color: #fff;

  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
}

.footerIcons {
  background: black;
  grid-area: icon;
}
.footerLogo {
  background: black;
  grid-area: logo;
  padding-left: 3.90625vw;
}

@media (max-width: 900px) {
  div.gradC1:nth-child(13) {
    z-index: -100;
  }

  div.gradE1:nth-child(14) {
    z-index: -100;
  }
}

@media (max-width: 600px) {
  .container {
    display: block;
    margin-top: 0;
  }
  .address {
    display: none;
  }
  .email {
    display: none;
  }
  .phone {
    display: none;
  }
  .element {
    padding-top: 2rem;
  }
  .footerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex {
    margin-top: 0px;
    padding-top: 30px;
  }
}
